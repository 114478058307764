import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, ModalBody, Row } from "react-bootstrap";
import { RiArrowRightSLine } from "react-icons/ri";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useToasts } from "react-toast-notifications";
import ProviderInfoModal from "../../component/providerInfoModal";
import Labels from "../../config/Labels";
import { Images } from "../../constant/images";
import CreditCardScreen from "../creditCard/creditCard";

import { DashCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import InformationContainer from "../../component/informationContainer";
import ToastComponent from "../../component/toast";
import {
  Add_New_Order_Method,
  Add_Primary_Card,
  Check_Promo_Code_Method,
  Delete_Credit_Card,
  Get_Credit_Cards,
} from "../../config/api";
import { objectToFormData } from "../../config/formData";
import labels from "../../config/Labels";
import {
  languageTranslationFunctionForTheTime,
  rating,
  replaceDecimalWithComma,
} from "../../config/Utility";
import { useDispatch } from "react-redux";
import { GetAllOrders } from "../../store/actions/orders";
import { Helmet } from "react-helmet";

const { trash } = Images;
const stripePromise = loadStripe(process.env.REACT_APP_API_KEY);
const language = localStorage.getItem("primaryLanguage") === "english";
let lang = localStorage.getItem("primaryLanguage");
const errorMessageLanguage = (responseMessage) => {
  const language = localStorage.getItem("primaryLanguage") === "english";
  return responseMessage[language ? "message" : "message_sp"];
};
export default function CheckOut(props) {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [toatlAmountService, setTotalAmountService] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const VAT = 21;
  const vat = 1.21;
  const [creditCards, setCreditCards] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSpinner1, setShowSpinner1] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [cardId, setCardId] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeResponse, setPromoCodeResponse] = useState(null);
  const [code, setCode] = useState("");
  const [isPromo, setIsPromo] = useState(false);
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [formvisible, setFormVisible] = useState(true);
  const [primaryCards, setPrimaryCards] = useState([]);
  const [supplementry, setSupplementry] = useState(false);
  const [providerInfo, setProviderInfo] = useState(false);
  const [serviceClicked, setServiceClicked] = useState(false);
  const [amountAfterDiscount, setAmountAfterDiscount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);

  const { addToast } = useToasts();

  const totalPrice =
    Number(props?.location?.state?.serviceItem?.original_price || 0) +
    Number(props?.location?.state?.serviceItem?.supplementary_fees || 0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showConfirmationPopup]);

  useEffect(() => {
    let taxAmount =
      (Number(props?.location?.state?.serviceItem?.reduced_price) / vat) * 0.21;

    setTotalTax(taxAmount);
    let totalAmount = Number(
      props?.location?.state?.serviceItem?.reduced_price || 0
    );
    setTotalAmountService(totalAmount);
  }, [promoCodeResponse]);
  useEffect(() => {
    getCards();
  }, []);

  const closeToast = () => {
    setShowToast(false);
  };

  const closeToast1 = () => {
    setShowToast1(false);
  };

  const getCards = () => {
    Get_Credit_Cards(
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },

      (value) => {
        let mappedCrads = value?.map((card) => {
          if (card.is_default === 1) {
            setCustomerId(card.customer_id);
            return {
              ...card,
              checked: true,
            };
          } else {
            return {
              ...card,
              checked: false,
            };
          }
        });

        setCreditCards(mappedCrads);
      }
    );
  };

  const CheckpromoCodeMethod = () => {
    if (promoCode?.trim()?.length > 0) {
      const obj = { code: promoCode };
      Check_Promo_Code_Method(
        obj,
        (res) => {
          //setPromoCodeResponse(res?.data?.response.detail);
          const value = res?.data?.response.detail;
          let promoCodeDiscount =
            toatlAmountService * (Number(value.discount) / 100);

          setPromoDiscount(promoCodeDiscount.toFixed(2));
          let afterPromoCodeAmount = toatlAmountService - promoCodeDiscount;
          setAmountAfterDiscount(afterPromoCodeAmount);
          setCode(value.code);
          setDiscount(value.discount);
          setPromoCode("");
          setIsPromo(true);
        },
        (error) => {
          if (error?.response?.status === 422) {
            addToast(errorMessageLanguage(error?.response?.data?.error), {
              appearance: "error",
              autoDismiss: 1000,
              content: errorMessageLanguage(error?.response?.data?.error),
            });
          } else if (error?.response?.status === 409) {
            addToast(errorMessageLanguage(error?.response?.data?.error), {
              appearance: "error",
              autoDismiss: 1000,
              content: errorMessageLanguage(error?.response?.data?.error),
            });
          } else {
            addToast("Network Error!", {
              appearance: "error",
              autoDismiss: 1000,
              content: "Network Error!",
            });
          }
        }
      );
    }
  };

  const primaryCard = (card_id) => {
    Add_Primary_Card(
      { card_id: card_id },

      (value) => {
        setSuccess(value);

        setPrimaryCards(value);
        getCards();
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast1(value);
      }
    );
  };

  const onClickItem = (cardItem, index) => {
    const updatedArray = creditCards?.map((c, i) => {
      if (i === index) {
        return {
          ...c,
          checked: !c.checked,
        };
      } else {
        return {
          ...c,
          checked: false,
        };
      }
    });
    setCustomerId(cardItem.customer_id);
    setCreditCards(updatedArray);
  };

  const deleteCreditCardMethod = () => {
    Delete_Credit_Card(
      cardId,
      (value) => {
        setShowSpinner1(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast1(value);
      },
      (value) => {
        setCreditCards(value);
      },
      () => {
        setIsConfirmationModal(false);
        getCards();
        setCustomerId("");
      },
      addToast
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [show]);

  let currentCard = creditCards?.filter((item) => item?.is_default == 1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const visibleClose = () => setVisible(false);
  const visibleShow = () => setVisible(true);

  const placeOrderFunction = () => {
    if (totalPrice <= 0) {
      createOrder();
    } else {
      if (creditCards.length === 0) {
        setIsPaymentModal(true);
        setFormVisible(false);
        addToast(labels.pleaseAddPaymentMethod, {
          autoDismiss: 1500,
        });
        return;
      }
      if (creditCards.length >= 0) {
        createOrder();
      }
    }
  };

  const createOrder = () => {
    let finalObj = {
      customer_id: customerId || null,
      service_id: props?.location?.state.service,
      service_provider_id:
        props.location.state.service_provider.service_provider_id,
      is_promo: promoCodeResponse === null ? 0 : 1,
      data: JSON.stringify(props.location.state.data),
      amount: toatlAmountService,
      after_discount_amount:
        amountAfterDiscount === 0 ? toatlAmountService : amountAfterDiscount,
      code: amountAfterDiscount === 0 ? "" : code,
      supplementary_fees: Number(
        props?.location?.state?.serviceItem?.supplementary_fees
      ),
    };
    let data = objectToFormData(finalObj);

    Add_New_Order_Method(
      finalObj,
      setShowSpinner1,
      (res) => {
        setSuccess(true);
        setMessage(res);
        setShowConfirmationPopup(true);

        dispatch(
          GetAllOrders((failure) => {
            setMessage(failure);
            setSuccess(false);
            setShowToast(true);
          })
        );
      },
      (error) => {
        if (error?.response?.status === 422) {
          addToast(errorMessageLanguage(error?.response?.data?.error), {
            appearance: "error",
            autoDismiss: 1000,
            content: errorMessageLanguage(error?.response?.data?.error),
          });
        } else if (error?.response?.status === 409) {
          addToast(errorMessageLanguage(error?.response?.data?.error), {
            appearance: "error",
            autoDismiss: 1000,
            content: errorMessageLanguage(error?.response?.data?.error),
          });
        } else {
          addToast("Network Error!", {
            appearance: "error",
            autoDismiss: 1000,
            content: "Network Error!",
          });
        }
      }
    );
  };
  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header
          className="d-flex justify-content-center modal-header border-0"
          closeButton
        >
          <div>
            <Modal.Title>{labels.information}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{ width: "500px", height: "300px", overflowY: "auto" }}
        >
          <InformationContainer
            info={
              props?.location?.state?.serviceItem?.info == null
                ? ""
                : props?.location?.state?.serviceItem?.[
                    language ? "info" : "info_sp"
                  ]
            }
          />
        </Modal.Body>
        <Modal.Footer className=" border-0"></Modal.Footer>
      </Modal>

      <section id="Content" className="pt-150 pb-150 ls  fadeInUp">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col sm="6" className="">
              {showToast1 === true ? (
                <ToastComponent
                  message={message}
                  toastClose={closeToast1}
                  success={success}
                />
              ) : (
                ""
              )}
              <h2 className="strong-header element-header pull-left text-center w-100">
                {labels.checkout}
              </h2>

              <section className="order-summary element-emphasis-weak border-radius-10 p-0">
                <div className="clearfix"></div>
                {/* <!-- SHOP SUMMARY ITEM --> */}
                <div className="m-3">
                  <article
                    className="shop-summary-item element-emphasis-weak border-radius-10 position-relative mt-10"
                    style={{ height: "150px" }}
                  >
                    <img
                      src={props?.location?.state?.serviceItem?.avatar}
                      alt="Shop item in cart"
                      style={{
                        objectFit: "contain",
                        width: "100px",
                        height: "100px",
                      }}
                    />
                    <header className="item-info-name-features-price mb-10">
                      <div className="fontSize16px mb-0 ">
                        <div>
                          {/* <a href="04-shop-product-single.html"> */}
                          <strong>
                            {
                              props?.location?.state?.serviceItem?.[
                                language ? "name" : "name_sp"
                              ]
                            }
                          </strong>
                          {/* </a> */}
                          <br />
                          <span
                            className="features "
                            style={{ color: "green" }}
                          >
                            {Number(
                              props?.location?.state?.serviceItem
                                ?.original_price || 0
                            ) +
                              Number(
                                props?.location?.state?.serviceItem
                                  ?.supplementary_fees || 0
                              ) !==
                            0
                              ? " €" +
                                replaceDecimalWithComma(
                                  Number(
                                    props?.location?.state?.serviceItem
                                      ?.original_price || 0
                                  ) +
                                    Number(
                                      props?.location?.state?.serviceItem
                                        ?.supplementary_fees || 0
                                    )
                                )
                              : labels.free}
                            {props?.location?.state?.serviceItem
                              ?.service_pack_type !== "fixed price" && (
                              <span className="mx-50">
                                {
                                  props?.location?.state?.serviceItem
                                    ?.service_pack_type
                                }{" "}
                              </span>
                            )}
                          </span>
                          <div>
                            <span
                              className="features "
                              style={{ color: "green" }}
                            >
                              {props?.location?.state?.serviceItem ===
                              "monthly" ? (
                                labels?.monthly
                              ) : (
                                <>
                                  {labels.estimatedTime}{" "}
                                  {props?.location?.state?.serviceItem?.time}{" "}
                                  {languageTranslationFunctionForTheTime(
                                    props?.location?.state?.serviceItem?.time,
                                    props?.location?.state?.serviceItem
                                      ?.time_type
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      {(
                        ((Number(
                          props?.location?.state?.serviceItem?.original_price ||
                            0
                        ) -
                          Number(
                            props?.location?.state?.serviceItem
                              ?.reduced_price || 0
                          )) /
                          Number(
                            props?.location?.state?.serviceItem
                              ?.original_price || 0
                          )) *
                        100
                      ).toFixed(0) > 0 ? (
                        <>
                          <span className="text-danger">
                            {" €"}
                            {replaceDecimalWithComma(
                              Number(
                                props?.location?.state?.serviceItem
                                  ?.reduced_price || 0
                              ) +
                                Number(
                                  props?.location?.state?.serviceItem
                                    ?.supplementary_fees || 0
                                )
                            )}{" "}
                          </span>
                          <span className="text-danger mx-5">
                            {(
                              ((Number(
                                props?.location?.state?.serviceItem
                                  ?.original_price || 0
                              ) -
                                Number(
                                  props?.location?.state?.serviceItem
                                    ?.reduced_price || 0
                                )) /
                                Number(
                                  props?.location?.state?.serviceItem
                                    ?.original_price || 0
                                )) *
                              100
                            ).toFixed(0)}
                            % {Labels.discount}
                          </span>{" "}
                        </>
                      ) : null}
                    </header>
                    <img
                      onClick={() => {
                        setShow(true);
                        setServiceClicked(true);
                      }}
                      src={Images.infoo.default}
                      className="position-absolute cursorPointer"
                      style={{ width: 20, height: 20, top: 50, right: 0 }}
                    />
                  </article>

                  <article className="shop-summary-item element-emphasis-weak border-radius-10 position-relative mt-10">
                    <img
                      src={props?.location?.state?.service_provider?.avatar}
                      alt="Shop item in cart"
                      style={{
                        borderRadius: 10,
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                    <header className="item-info-name-features-price mb-10">
                      <h3 className="fontSize16px mb-0 ">
                        <strong>
                          {props?.location?.state?.service_provider?.name}
                        </strong>
                      </h3>
                      <div>
                        <span>{labels.position}: </span>
                        <strong>
                          {(
                            props?.location?.state?.service_provider?.[
                              lang === "english" ? "position" : "position_sp"
                            ] || "N/A"
                          )?.substring(0, 20)}
                          {(
                            props?.location?.state?.service_provider?.[
                              lang === "english" ? "position" : "position_sp"
                            ] || "N/A"
                          )?.length > 20
                            ? "... "
                            : ""}
                        </strong>
                      </div>
                      <div>
                        <span>{labels.experience}: </span>
                        <strong>
                          {props?.location?.state?.service_provider?.experience}{" "}
                          {labels.years}
                        </strong>
                      </div>
                      <div>
                        <span>{labels.rating}: </span>
                        <span>
                          {props?.location?.state?.service_provider
                            ?.fake_rating != null
                            ? Number(
                                props?.location?.state?.service_provider
                                  ?.fake_rating || 0
                              ).toFixed(1)
                            : !props.location.state?.service_provider
                                ?.average_rating?.length
                            ? "0.0"
                            : Number(
                                props?.location?.state?.service_provider
                                  ?.average_rating || 0
                              ).toFixed(1)}
                          <span style={{ color: "#FED42D" }}>
                            {rating(
                              props?.location?.state?.service_provider
                                ?.fake_rating != null
                                ? Number(
                                    props?.location?.state?.service_provider
                                      ?.fake_rating || 0
                                  ).toFixed(1)
                                : !props.location.state?.service_provider
                                    ?.average_rating?.length
                                ? "0.0"
                                : Number(
                                    props?.location?.state?.service_provider
                                      ?.average_rating || 0
                                  ).toFixed(1)
                            )}
                          </span>
                        </span>
                      </div>
                      {/* <strong>
                        <span>
                          {labels.position}:{" "}
                          {props?.location?.state?.service_provider
                            ?.position === null ||
                          props?.location?.state?.service_provider?.position ===
                            null
                            ? "N/A"
                            : props?.location?.state?.service_provider?.position?.substring(
                                1,
                                20
                              )}
                          ...
                        </span>
                        <br />
                        <span className="features " style={{ color: "green" }}>
                          {labels.experience}{" "}
                          {props?.location?.state?.service_provider?.experience}{" "}
                          {labels.years}
                        </span>
                      </strong> */}

                      {/* <span
                        className="features d-flex flex-row"
                        style={{ color: "black", position: "top" }}
                      >
                        <strong className="">
                          {props?.location?.state?.service_provider
                            ?.fake_rating != null
                            ? Number(
                                props?.location?.state?.service_provider
                                  ?.fake_rating || 0
                              ).toFixed(1)
                            : !props.location.state?.service_provider
                                ?.average_rating?.length
                            ? "0.0"
                            : Number(
                                props?.location?.state?.service_provider
                                  ?.average_rating || 0
                              ).toFixed(1)}
                        </strong>
                        <span>
                          <img
                            className="partnerStarIconInfo m-0  "
                            style={{ width: 20, height: 20 }}
                            src={Images.shareWin.default}
                            alt="info"
                          />
                        </span>
                      </span> */}
                    </header>
                    <img
                      onClick={() => {
                        setShowModal(true);
                      }}
                      src={Images.infoo.default}
                      className="position-absolute cursorPointer"
                      style={{ width: 20, height: 20, top: 60, right: 0 }}
                    />
                  </article>
                  <div className="text-center mt-15"></div>
                </div>
                {!totalPrice <= 0 ? (
                  <>
                    {" "}
                    <div className="m-4">
                      <h5 className=" marginBottom0 fontSize16px">
                        {labels.promo}
                      </h5>
                      <div className="d-flex flex-column flex-sm-row justify-content-between">
                        <div className="w-100 d-flex alignItemsCenter">
                          {isPromo ? (
                            <>
                              <div className="d-flex  justify-content-between align-items-center w-100">
                                <div>{labels.promoCodeIsApplied}</div>
                                <div className="textAlignCenter width30 ">
                                  <span
                                    className="btn btn-danger float-right continue1"
                                    onClick={() => {
                                      setAmountAfterDiscount(0);
                                      setPromoCodeResponse(null);
                                      setIsPromo(false);
                                    }}
                                  >
                                    {labels.delete}
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="d-flex  justify-content-between align-items-center w-100">
                              <input
                                type="text"
                                placeholder={labels.enterPromoCodeHere}
                                className="form-control w-50"
                                id="promo-code"
                                onChange={(e) => {
                                  setPromoCode(e.target.value);
                                }}
                              />
                              <div className="textAlignCenter width30 ">
                                <span
                                  className="btn btn-primary float-right continue1"
                                  onClick={() => CheckpromoCodeMethod()}
                                >
                                  {labels.apply}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="shadow  m-10 p-10"
                      style={{ cursor: "pointer", borderRadius: "50px" }}
                      onClick={() => {
                        setIsPaymentModal(true);
                        setFormVisible(false);
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center justify-content-center">
                          <h6 className="ml-2 fontSize16px">
                            {currentCard.length == 1 ? (
                              <span className="card-no">{`*** *** *** ${currentCard[0]?.last_4_digit}`}</span>
                            ) : (
                              <strong>{labels.addPayment}</strong>
                            )}
                          </h6>
                        </div>
                        <RiArrowRightSLine size={30} />
                      </div>
                    </div>
                  </>
                ) : null}

                {/* <div className="mx-4 mt-2 ">
                  <p
                    className="fontSize13px font-weight-normal"
                    style={{ lineHeight: "normal" }}
                  >
                    {labels?.freeServicePaymentMessage}
                  </p>
                </div> */}
                <div className="m-4">
                  <strong>
                    <div className="d-flex justify-content-between w-100">
                      <div>{labels.baseImponible}</div>
                      <div>
                        {`${replaceDecimalWithComma(
                          Number(
                            props?.location?.state?.serviceItem
                              ?.reduced_price || 0
                          ) / 1.21
                        )}` + " €"}
                        {/* {`${replaceDecimalWithComma(
                          parseFloat(
                            props?.location?.state?.serviceItem
                              ?.reduced_price || 0
                          ) - parseFloat(totalTax)
                        )}` + " €"} */}
                        {/* {replaceDecimalWithComma(
                          Number(
                            props?.location?.state?.serviceItem
                              ?.reduced_price || 0
                          )
                        )} */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex w-100 justify-content-between">
                        <div>
                          {labels.vat} {VAT}%
                        </div>
                        <div>
                          {`${replaceDecimalWithComma(Number(totalTax))}` +
                            " €"}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between w-100">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSupplementry(!supplementry);
                        }}
                      >
                        {supplementry ? (
                          <DashCircleFill size={20} />
                        ) : (
                          <PlusCircleFill size={20} color="#000000" />
                        )}{" "}
                        {labels.supplementaryFees}{" "}
                      </div>
                      <div>
                        {replaceDecimalWithComma(
                          props?.location?.state?.serviceItem
                            ?.supplementary_fees
                        )}
                        {" €"}
                      </div>
                    </div>

                    {supplementry && (
                      <div>
                        {props?.location?.state?.serviceItem?.service_supplementary_fees?.map(
                          (item) => {
                            return (
                              <div
                                className="d-flex justify-content-between w-100"
                                style={{ fontWeight: "lighter" }}
                              >
                                <div className="">{item?.name}</div>
                                <div className="">
                                  {replaceDecimalWithComma(item?.price)}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}

                    <hr className="py-1 my-1" />
                    <div className="d-flex justify-content-between w-100">
                      <div>Total</div>
                      <div>
                        {replaceDecimalWithComma(
                          Number(
                            Number(
                              props?.location?.state?.serviceItem
                                ?.reduced_price || 0
                            ) +
                              Number(
                                props?.location?.state?.serviceItem
                                  ?.supplementary_fees || 0
                              )
                          )
                        )}{" "}
                        &euro;
                      </div>
                    </div>
                    {isPromo ? (
                      <>
                        {amountAfterDiscount !== 0 ? (
                          <>
                            <div className="d-flex justify-content-between w-100">
                              <div className="d-flex w-100 justify-content-between">
                                <div>
                                  <span>{`${labels.promo}(${code}) ${discount}%`}</span>
                                </div>
                                <div>
                                  {` - ${
                                    replaceDecimalWithComma(promoDiscount) +
                                    " €"
                                  }`}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {amountAfterDiscount !== 0 ? (
                          <div>
                            <div className="d-flex justify-content-between w-100">
                              <div className="d-flex w-100 justify-content-between">
                                <div>
                                  <span>{labels.grandTot}</span>
                                </div>
                                <div>
                                  <span>
                                    {replaceDecimalWithComma(
                                      Number(amountAfterDiscount) +
                                        Number(
                                          props?.location?.state?.serviceItem
                                            ?.supplementary_fees || 0
                                        )
                                    ) + " €"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </strong>
                </div>
              </section>

              <div className="d-flex justify-content-center w-100">
                <span
                  className="btn btn-primary float-right continue1 mt-3 w-75"
                  onClick={() => placeOrderFunction()}
                >
                  {showSpinner1 ? labels.loading : labels.checkOutSubmit}
                </span>
              </div>

              <Modal
                className="modelBackground"
                show={isPaymentModal}
                onHide={() => setIsPaymentModal(false)}
                size="lg"
              >
                <ModalBody className="bg-white p-0 border-radius-10 alignItemsCenter textAlignCenter m-auto  my-20 ">
                  <button
                    type="button"
                    className="close absolute"
                    data-dismiss="modal"
                    onClick={() => setIsPaymentModal(false)}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  <div
                    className="shadow"
                    style={{
                      backgroundColor: "white",
                      width: "500px",
                      height: "auto",
                      paddingTop: "30px",
                      borderRadius: "11px",
                    }}
                  >
                    {creditCards.length == "0" ? (
                      <>
                        <h4>{labels.addPaymentCard}</h4>

                        <Elements stripe={stripePromise}>
                          <CreditCardScreen
                            modalClose={() => setIsPaymentModal(false)}
                            getCreditCards={getCards}
                          />
                          <div className="mx-4 pb-3 ">
                            <p
                              className="fontSize13px font-weight-normal"
                              style={{ lineHeight: "normal" }}
                            >
                              {labels?.freeServicePaymentMessage}
                            </p>
                          </div>
                        </Elements>
                      </>
                    ) : (
                      <div className="">
                        <h5 className="textAlignCenter">{labels.selectCard}</h5>

                        {showSpinner
                          ? labels.loading
                          : creditCards?.map((cardDetail, index) => {
                              return (
                                <>
                                  <div className="card-item-container">
                                    <div className="card-item ml-5" key={index}>
                                      <span className="card-no">{`*** *** *** ${cardDetail.last_4_digit}`}</span>
                                      {cardDetail.checked === true ? (
                                        <div className="radio-upper">
                                          <div className="radio-inner"></div>
                                        </div>
                                      ) : (
                                        <div
                                          className="radio-upper"
                                          onClick={() => {
                                            onClickItem(cardDetail, index);
                                            primaryCard(cardDetail?.card_id);
                                          }}
                                        ></div>
                                      )}
                                    </div>

                                    <img
                                      className="mb-10"
                                      src={trash.default}
                                      onClick={() => {
                                        setIsConfirmationModal(true);
                                        setCardId(cardDetail.card_id);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <br />
                                  </div>
                                </>
                              );
                            })}
                        {showToast === true ? (
                          <ToastComponent
                            message={message}
                            toastClose={closeToast}
                            success={success}
                          />
                        ) : (
                          ""
                        )}
                        {!formvisible && (
                          <div
                            className="cursorPointer p-10"
                            onClick={() => {
                              setFormVisible(true);
                            }}
                            style={{ width: "100%" }}
                          >
                            <p>
                              <strong>{labels.addpaymentcard}</strong>
                            </p>
                          </div>
                        )}

                        {formvisible && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            fill="currentColor"
                            className="bi bi-dash cursorPointer"
                            viewBox="0 0 16 16"
                            onClick={() => {
                              setFormVisible(false);
                            }}
                          >
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                          </svg>
                        )}

                        {formvisible && (
                          <Elements stripe={stripePromise}>
                            <CreditCardScreen
                              modalClose={() => setIsPaymentModal(false)}
                              getCreditCards={getCards}
                            />
                          </Elements>
                        )}
                      </div>
                    )}
                  </div>
                </ModalBody>
              </Modal>
              <Modal
                className="modelBackground"
                show={showConfirmationPopup}
                // onHide={() => setShowConfirmationPopup(false)}
                size="lg"
              >
                <ModalBody className="bg-white border-radius-10 p-20 w-50 alignItemsCenter textAlignCenter m-auto height_72 my-20">
                  {/* <button
                    type="button"
                    className="close  absolute"
                    data-dismiss="modal"
                    onClick={() => setShowConfirmationPopup(false)}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                  <div>
                    <img className="mt-20" src={Images.check.default} />
                    <h5 className="mx-30 ">{success === true && message}</h5>
                    <button
                      onClick={() => {
                        localStorage.setItem("orderCurrentActiveTab", "home");
                        push("/1/myorder");
                        setShowConfirmationPopup(false);
                      }}
                      className="mb-3  ml-1"
                    >
                      {labels.done}
                    </button>
                  </div>
                </ModalBody>
              </Modal>
              <Modal
                className="modelBackground"
                show={isConfirmationModal}
                onHide={() => setIsConfirmationModal(false)}
                size="lg"
              >
                <ModalBody className="bg-white border-radius-10 alignItemsCenter textAlignCenter m-auto height_71 my-20">
                  <button
                    type="button"
                    className="close  absolute"
                    data-dismiss="modal"
                    onClick={() => setIsConfirmationModal(false)}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  {showToast1 === true ? (
                    <ToastComponent
                      message={message}
                      toastClose={closeToast1}
                      success={success}
                    />
                  ) : (
                    ""
                  )}
                  <div className="p-3">
                    <h5 className="textAlignCenter">{labels.confirmation}</h5>
                    <p className="mx-30">{labels.deleteCard}</p>
                    <div className="d-flex justify-content-around align-content-center">
                      <div className="w-50 pr-1">
                        <button
                          onClick={() => setIsConfirmationModal(false)}
                          className="w-100"
                          style={{
                            backgroundColor: "red",
                            borderColor: "red",
                          }}
                        >
                          {labels.cancel}
                        </button>{" "}
                      </div>

                      <div className="w-50 pl-1">
                        <button
                          onClick={() => deleteCreditCardMethod()}
                          className="w-100"
                        >
                          {showSpinner1 ? labels.loading : " Ok "}
                        </button>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
        <ProviderInfoModal
          info={props?.location?.state?.service_provider}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      </section>
    </>
  );
}
