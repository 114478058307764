import React, {
  createContext,
  useCallback,
  useLayoutEffect,
  useReducer,
} from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import { ToastProvider } from "react-toast-notifications";
import "./App.css";
import { Footer, ServiceArea, ServiceItem, SideMenu } from "./component";
import NavBar from "./component/navbar";
import WorkWithUsGlobalModal from "./component/workWithUsGlobalModal";
import ThemeProvider, { Context } from "./config/Store";
import About from "./screen/about";
import Alerts from "./screen/alerts";
import Budgets from "./screen/budgets";
import CheckOut from "./screen/checkout";
import Cookies from "./screen/Cookies";
import DisputedOrderMessage from "./screen/disputeorderMessages";
import Documentaion from "./screen/documentation";
import FAQ from "./screen/faq";
import Help from "./screen/help";
import Home from "./screen/home";
import InnerService from "./screen/innerservice";
import Introduction from "./screen/introduction";
import MyAccount from "./screen/myaccount";
import MyMessages from "./screen/mymessages";
import NewAccount from "./screen/newaccount";
import MyOrder from "./screen/order/myOrder";

import OrderDetail from "./screen/order/orderDetail";
import OrderNotifications from "./screen/orderNotification";
import Partners from "./screen/partners";
import Payment from "./screen/payment/payment";
import PersonalProfile from "./screen/personalProfile";
import Privacy from "./screen/privacy";
import mySavedOrder from "./screen/savedOrder/mySavedOrder";
import SecondLevelServices from "./screen/secondlevelservices";
import SelectRegion from "./screen/SelectRegion";
import ShareWin from "./screen/sharewin/sharewin";
import SingleService from "./screen/singleservice";
import SpecialServices from "./screen/specialServices";
import SpecficOrderMessage from "./screen/specificordermessage";
import TermsCondition from "./screen/Terns&Cond";

import { configure, getPreferences, hide, show } from "cookie-though";
import ModalForAMobile from "./component/modalForAMobile";
import { getCookie } from "./config/Utility";
import { cookieBannerConfig } from "./constant";

export const WorkWithUsModalContext = createContext();

const workWithUsModalInitialState = false;
const workWithUsModalReducer = (state, action) => {
  switch (action) {
    case "open":
      return true;
    case "close":
      return false;
    default:
      return state;
  }
};

function App() {
  useLayoutEffect(() => {
    document.body.style.zoom = "90%";
  }, []);

  const analyticsCookieCheck = getCookie("cookie-preferences");

  if (!analyticsCookieCheck.trim().length) {
    configure(cookieBannerConfig);
    show();
    const userPreferences = getPreferences();

    if (
      userPreferences?.cookieOptions?.every((pref) => pref.isEnabled === true)
    ) {
      hide();
    }
  }

  const [modalVisible, dispatch] = useReducer(
    workWithUsModalReducer,
    workWithUsModalInitialState
  );

  const MyOrderFunc = useCallback((props) => {
    return (
      <ToastProvider>
        <div className="container">
          <div className="row pt-150">
            <div className="col-lg-3 col-md-4 col-12">
              <SideMenu path={props.location.pathname} />
            </div>
            <div className="col-lg-9 col-md-8 p-0">
              <Route path="/1/myorder" exact component={MyOrder} />
              <Route path="/1/mybudget" exact component={Budgets} />
              <Route path="/1/myaccount" exact component={MyAccount} />
              <Route path="/1/mysavedorder" exact component={mySavedOrder} />
              <Route path="/1/mymessages" exact component={MyMessages} />
              <Route path="/1/alerts" exact component={Alerts} />

              <Route path="/1/share-win" exact component={ShareWin} />
              <Route path="/1/help" exact component={Help} />
              <Route
                path="/1/SpecialPriceServices"
                exact
                component={SpecialServices}
              />
            </div>
          </div>
        </div>
      </ToastProvider>
    );
  }, []);
  return (
    <>
      <WorkWithUsModalContext.Provider
        value={{ modalState: modalVisible, modalDispatch: dispatch }}
      >
        <ThemeProvider>
          <Context.Consumer>
            {(value) => {
              return (
                <>
                  <ToastProvider>
                    <Router basename={"/"}>
                      <WorkWithUsGlobalModal />
                      <NavBar />
                      <Route path="/" exact component={Home} />
                      <Route
                        path="/service/:id"
                        exact
                        component={SingleService}
                      />
                      <Route
                        path="/secondLevelServices/:id"
                        exact
                        component={SecondLevelServices}
                      />
                      <Route
                        path="/allservices/"
                        exact
                        component={ServiceItem}
                      />

                      <Route
                        path="/innerservice/:id"
                        exact
                        component={InnerService}
                      />
                      <Route
                        path="/selectregion"
                        exact
                        component={SelectRegion}
                      />
                      <Route path="/cookies" exact component={Cookies} />
                      <Route
                        path="/personalprofile"
                        exact
                        component={PersonalProfile}
                      />
                      <Route path="/faq" exact component={FAQ} />
                      <Route
                        path="/documentaion"
                        exact
                        component={Documentaion}
                      />
                      <Route path="/branches" exact component={Introduction} />
                      <Route path="/1" component={MyOrderFunc} />
                      <Route path="/orderdetail/:id" component={OrderDetail} />
                      <Route path="/checkout" exact component={CheckOut} />
                      <Route path="/payment" exact component={Payment} />
                      <Route path="/privacy" exact component={Privacy} />
                      <Route path="/newaccount" exact component={NewAccount} />
                      <Route
                        path="/termscondition"
                        exact
                        component={TermsCondition}
                      />
                      <Route path="/about" exact component={About} />
                      <Route
                        path="/serviceArea"
                        exact
                        component={ServiceArea}
                      />
                      <Route path="/partners" exact component={Partners} />
                      <Route
                        path="/specficordermessage/:id"
                        exact
                        component={SpecficOrderMessage}
                      />
                      <Route
                        path="/order-notification/:id"
                        exact
                        component={OrderNotifications}
                      />
                      <Route
                        path="/disputedordermessages/:id"
                        exact
                        component={DisputedOrderMessage}
                      />
                      <Route path="/mymessages" exact component={MyMessages} />

                      <Footer />
                    </Router>
                  </ToastProvider>
                </>
              );
            }}
          </Context.Consumer>
        </ThemeProvider>
        
        {JSON?.parse(localStorage.getItem("isMobileApp")) === null ? (
          <ModalForAMobile />
        ) : null}
        
      </WorkWithUsModalContext.Provider>
    </>
  );
}

export default App;
